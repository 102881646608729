import React from 'react';
import Radium from 'radium';
import {findObjectById} from './Helpers'
import {server} from './Serverconfig'
import AdminArea from './admin/AdminArea'
import Login from './admin/Login'
import Board from './board/Board'
import WorkshopRegister from './workshop/WorkshopRegister'
import {BrowserRouter, Routes, Route} from "react-router-dom";

let backendserver = server;

class App extends React.Component
{  
  constructor(props)
  {
    super(props);    
    this.state = {   
      courses: {},
      definitions: {},
      tablecourses: [],
      tabledates: [],
      tableregistrations: [],
      passdata: {},
      loginpass: "",
      isLoginSuccess: false
    };
  }

  handleChangePassword = event => {
    this.setState({
      loginpass: event.target.value
    });
  };

  handleCheckPassword = event => {
    event.preventDefault();
    if(this.state.loginpass ===  this.state.passdata.value)
    {
      this.setState({
        isLoginSuccess: true
      });
    }
    else
    {
      this.setState({
        loginpass: ""
      });   
    }
  };

  handleCancelCourse = formstate => {
    if(formstate.id !== "")
    {
      let { tablecourses } = this.state;
      findObjectById(tablecourses, formstate.id).updating = false;
    }
  };

  handleCancelDate = formstate => {
    if(formstate.id !== "")
    {
      let { tabledates } = this.state;
      findObjectById(tabledates, formstate.id).updating = false;
    }
  };

  handleCancelRegistration = formstate => {
    if(formstate.id !== "")
    {
      let { tableregistrations } = this.state;
      findObjectById(tableregistrations, formstate.id).updating = false;
    }
  };

  handleUpdateCourse = formstate => {
    let { tablecourses } = this.state;
    findObjectById(tablecourses, formstate.id).updating = true;
  };

  handleUpdateDate = formstate => {
    let { tabledates } = this.state;
    findObjectById(tabledates, formstate.id).updating = true;
  };

  handleUpdateRegistration = formstate => {
    let { tableregistrations } = this.state;
    findObjectById(tableregistrations, formstate.id).updating = true;
  };
  

  handleEditCourse = formstate => {
    fetch(backendserver + "/editcource", {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formstate)
    })
    .then((res) => res.json())
    .then((tabledata) => this.setState({tablecourses: tabledata }));
  };

  handleEditDate = formstate => {
    fetch(backendserver + "/editdate", {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formstate)
    })
    .then((res) => res.json())
    .then((tabledata) => this.setState({tabledates: tabledata }));
  };

  handleEditRegistration = formstate => {
    fetch(backendserver + "/editregistration", {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formstate)
    })
    .then((res) => res.json())
    .then((tabledata) => this.setState({tableregistrations: tabledata }));
  };

  handleSubmitCourse = formstate => {
    fetch(backendserver + "/submitcource", {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formstate)
    })
    .then((res) => res.json())
    .then((tabledata) => this.setState({tablecourses: tabledata }));
  };

  handleSubmitDate = formstate => {
    fetch(backendserver + "/submitdate", {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formstate)
    })
    .then((res) => res.json())
    .then((tabledata) => this.setState({tabledates: tabledata }));
  };

  handleSubmitRegistration = formstate => {
    console.log(formstate)
    fetch(backendserver + "/submitregistration", {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formstate)
    })
    .then((res) => res.json())
    .then((tabledata) => this.setState({tableregistrations: tabledata }));
  };

  handleDeleteCourse = formstate => {
    fetch(backendserver + "/deletecource", {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formstate)
    })
    .then((res) => res.json())
    .then((tabledata) => 
    {
      this.setState({tablecourses: tabledata });
      fetch(backendserver + "/tabledates")
      .then((res) => res.json())
      .then((tabledata) => this.setState({tabledates: tabledata }));
    });
  };

  handleDeleteDate = formstate => {
    fetch(backendserver + "/deletedate", {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formstate)
    })
    .then((res) => res.json())
    .then((tabledata) => this.setState({tabledates: tabledata }));
  };

  handleDeleteRegistration = formstate => {
    fetch(backendserver + "/deleteregistration", {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formstate)
    })
    .then((res) => res.json())
    .then((tabledata) => this.setState({tableregistrations: tabledata }));
  };


  componentDidMount()
  {
    fetch(backendserver + "/courses")
    .then((res) => res.json())
    .then((coursedata) => this.setState({courses: coursedata}));

    fetch(backendserver + "/definitions")
    .then((res) => res.json())
    .then((definitiondata) => this.setState({definitions: definitiondata}));

    fetch(backendserver + "/tablecourses")
    .then((res) => res.json())
    .then((tabledata) => this.setState({tablecourses: tabledata }));

    fetch(backendserver + "/tabledates")
    .then((res) => res.json())
    .then((tabledata) => this.setState({tabledates: tabledata }));

    fetch(backendserver + "/tableregistrations")
    .then((res) => res.json())
    .then((tabledata) => this.setState({tableregistrations: tabledata }));

    fetch(backendserver + "/passdata")
    .then((res) => res.json())
    .then((password) => this.setState({passdata: password }));
  }
  

  render() 
  {
    return (
      <BrowserRouter className="App">
        <Routes>
          <Route path="" element={
            <Board serverdata={this.state}/>
          }/>
          <Route path="registerworkshop" element={
            <WorkshopRegister 
            definitions={this.state.definitions} 
            submitRegistration={this.handleSubmitRegistration}/>
          }/>
          <Route path="admin" element={
            this.state.isLoginSuccess
              ?<AdminArea 
                definitions={this.state.definitions} 
                courses={this.state.tablecourses} 
                dates={this.state.tabledates} 
                registrations={this.state.tableregistrations} 
                updateCourse={this.handleUpdateCourse} 
                updateDate={this.handleUpdateDate} 
                updateRegistration={this.handleUpdateRegistration} 
                cancelCourse={this.handleCancelCourse} 
                cancelDate={this.handleCancelDate} 
                cancelRegistration={this.handleCancelRegistration}
                editCourse={this.handleEditCourse} 
                editDate={this.handleEditDate} 
                editRegistration={this.handleEditRegistration} 
                deleteCourse={this.handleDeleteCourse} 
                deleteDate={this.handleDeleteDate} 
                deleteRegistration={this.handleDeleteRegistration} 
                submitCourse={this.handleSubmitCourse}
                submitDate={this.handleSubmitDate}
                submitRegistration={this.handleSubmitRegistration}/>
              :<Login value={this.state.loginpass} onChange={this.handleChangePassword} onSubmit={this.handleCheckPassword}/>
            }/>
          </Routes>
      </BrowserRouter>
    );
  }
}

export default Radium(App);
