import React from 'react';
import './Board.css'
import {findObjectById, getStartTimeWithOffset} from '../Helpers'
import SpaceLineBig from './SpaceLineBig'
import SpaceTable from './SpaceTable';
import TimeColumn from './TimeColumn'
import ConfigLine from './ConfigLine'


class Board extends React.Component
{  


  constructor(props)
  {

    super(props);    
    this.state = {      
      selectedDay: this.getDayValue(),
      selectedElement: {active: false, item: {}}
    };  
  }

  getDayValue()
  {
    const date = new Date();
    const weekday = date.getDay();
    let dayValue = 1; //Thu
    if(weekday > 3)
    {
      dayValue = weekday - 3; //Thu - Sat
    }
    else
    {
      if(weekday === 0)
      {
        dayValue = 4; // Sun
      }
    }
    return dayValue;
  }

  handleCloseSpaceLineBig()
  {
    this.setState({
      selectedElement: {active: false, item: {}}
    });  
  }

  handleLineSelection(e, element)
  {
    this.setState({
      selectedElement: {active: true, item: element}
    });  
  }

  handleTimeChangePreviousDay(daysettings)
  {
    this.setState(prevState => ({
      selectedDay: prevState.selectedDay > daysettings.firstday?prevState.selectedDay - 1:daysettings.firstday
    })); 
  }

  handleTimeChangeNextDay(daysettings)
  {
    this.setState(prevState => ({
      selectedDay: prevState.selectedDay < daysettings.lastday?prevState.selectedDay + 1:daysettings.lastday
    })); 
  }
  
  getStartHour (day)
  {
    let startTimesArr = [];
    let endTimesArr = [];
    if(day !== undefined)
    {
      const dayvalues = Object.values(day);
      dayvalues.forEach(space => {
        if(space !== undefined)
        {
          const spacevalues = Object.values(space);
          spacevalues.forEach(courses => {
            if(courses !== undefined)
            {
              courses.forEach(course => {
                if(course !== undefined)
                {
                  const starttime = course.starttime.split(":");
                  const starttimeWithOffset = getStartTimeWithOffset(starttime[0]);
                  startTimesArr.push(starttimeWithOffset);
                  const durationInMinutes = Number(starttime[1]) + course.duration - 1;
                  const durationInHours = (durationInMinutes - (durationInMinutes % 60)) / 60 + 1;
                  endTimesArr.push(starttimeWithOffset + durationInHours);
                }
              })
            }
          })
        }
      })
    }
    return {"start": Math.min(...startTimesArr), "end": Math.max(...endTimesArr)};
  }

  getTimeSettings(serverdata, selectedDay)
  {
    let timesettings = {};
    if(serverdata !== undefined)
    {
      let definitionsData = serverdata.definitions;
      let coursesData = serverdata.courses;

      let dayTag = "";
      if(definitionsData !== undefined)
      {
        let dayNames = definitionsData.days;
        if(dayNames !== undefined)
        {
          dayTag = findObjectById(dayNames, selectedDay).tag;
        }
      }

      if(coursesData !== undefined)
      {
        let day = coursesData[dayTag];
        if(day !== undefined)
        {
          timesettings = this.getStartHour(day);
        }
      }
    }
    return timesettings;
  }

  getDaySettings(serverdata)
  {
    let firstDayIndex = 0;
    let lastDayIndex = 0;

    if(serverdata !== undefined)
    {
      let definitionsData = serverdata.definitions;
      if(definitionsData !== undefined)
      {
        let dayNames = definitionsData.days;
        if(dayNames !== undefined)
        {
          firstDayIndex = dayNames.at(0).id;
          lastDayIndex = dayNames.at(-1).id;
        }
      }
    }
    return {firstday: firstDayIndex, lastday: lastDayIndex};
  }

  render() 
  {
    return (
      <div className="board">
        <SpaceLineBig element={this.state.selectedElement} onClickClose={() => this.handleCloseSpaceLineBig()} />
        <ConfigLine daysettings={this.getDaySettings(this.props.serverdata)} serverdata={this.props.serverdata} selectedDay={this.state.selectedDay} onClickPreviousDay={() => this.handleTimeChangePreviousDay(this.getDaySettings(this.props.serverdata))} onClickNextDay={() => this.handleTimeChangeNextDay(this.getDaySettings(this.props.serverdata))}/>
        <TimeColumn timesettings={this.getTimeSettings(this.props.serverdata, this.state.selectedDay)} serverdata={this.props.serverdata} selectedDay={this.state.selectedDay} />
        <SpaceTable timesettings={this.getTimeSettings(this.props.serverdata, this.state.selectedDay)} serverdata={this.props.serverdata} selectedDay={this.state.selectedDay} scrollWidth={this.state.scrollWidth} onClickLine={(e, element) => this.handleLineSelection(e, element)}/>
      </div>
    );
  }
}

export default Board;