import React from 'react';
import './Board.css'

class SpaceLineHeader extends React.Component 
{
  render() 
  {
    let startpos = 101 + 252 * (this.props.id - 1) - this.props.spaceScrollLeft;
    return (
      <div className="spacelineheader" style={{left: startpos + "px" }}>
          <div className="textworkspacenames">{this.props.name}</div>
          <div className="textworkspacenumber">{"Space: " + this.props.id}</div>
      </div>
    );
  }
}
export default SpaceLineHeader;