import React from 'react';
import dayjs from 'dayjs'
import './Admin.css'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';

class AdminTimePicker extends React.Component 
{
  render() 
  {
    return (
      <div className={this.props.prefix + "-field"}>
          <label className={this.props.prefix + "-label"}>{this.props.label}</label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileTimePicker className={this.props.prefix + "-timepicker" + (this.props.haserror? " " + this.props.prefix + "-input-element-error": " " + this.props.prefix + "-input-element-noerror") + (this.props.readonly?" " + this.props.prefix + "-disabled":"")} ampm={false} minutesStep={5} label="Please pick a time:" value={dayjs(this.props.value, "hh:mm:ss")}  name={this.props.name} onChange={this.props.onChange} disabled={this.props.readonly}/>
          </LocalizationProvider>
          <div className={this.props.prefix + "-input-error"}>{this.props.errormsg}</div>
      </div>
    );
  }
};

export default AdminTimePicker;
