import React from 'react';
import './Board.css'
import {headerHeight,headerHeightPrintOffset, hourHeightScreen,hourHeightPrint, hourHeightPrintOffset, findObjectById, getStartTimeWithOffset} from '../Helpers'
import SpaceLineHeader from './SpaceLineHeader';
import SpaceLine from './SpaceLine';

class SpaceColumn extends React.Component 
{

  renderSpaceLineElement(content, timesettings, definitionsData, space) 
  {
    if(space !== undefined )
    {
      space.courses.forEach(value =>
      {
        let starttimeArr = value.starttime.split(":");
        let starttimeWithOffset = getStartTimeWithOffset(starttimeArr[0]);
        let currentPositionScreen = headerHeight + ((starttimeWithOffset - timesettings.start) * hourHeightScreen) + ((Number(starttimeArr[1]) * hourHeightScreen / 60));
        let currentHeightScreen = value.duration * hourHeightScreen / 60 - (hourHeightScreen / 20);
        let currentPositionPrint = headerHeight + headerHeightPrintOffset + ((starttimeWithOffset - timesettings.start) * (hourHeightPrint+hourHeightPrintOffset)) + ((Number(starttimeArr[1]) * (hourHeightPrint+hourHeightPrintOffset) / 60));
        let currentHeightPrint = value.duration * hourHeightPrint / 60 - (hourHeightPrint / 20);
        
        currentPositionPrint += currentPositionPrint > 600?10:0;


        let lineElement  = {
          starttime: value.starttime,
          title: value.title,
          person: value.person,
          duration: value.duration,
          level: value.level,
          levelindex: value.level,
          lang: value.lang,
          tool: value.tool,
          participants: value.participants,
          description: value.description
        } 

        lineElement.level = findObjectById(definitionsData.levels, value.level).name;
        lineElement.lang = findObjectById(definitionsData.languages, value.lang).name;

        content.push(<SpaceLine key={value.starttime} levelindex={value.level} lineElement={lineElement} height_s={currentHeightScreen} pos_s={currentPositionScreen} height_p={currentHeightPrint} pos_p={currentPositionPrint} onClickLine={(e, element) => this.props.onClickLine(e, element)}/>);
      });
    }
  }
  renderSpaceLine(timesettings, serverdata, selectedDay) 
  {
    let content = [];
    if(serverdata !== undefined)
    {
      let coursesData = serverdata.courses;
      let definitionsData = serverdata.definitions;
      if(coursesData !== undefined && definitionsData !== undefined)
      {
        let dayTag = "";
        let dayNames = definitionsData.days;
        if(dayNames !== undefined)
        {
          dayTag = findObjectById(dayNames, selectedDay).tag;
        }
  
        let day = coursesData[dayTag];
        if(day !== undefined)
        {
          this.renderSpaceLineElement(content, timesettings, definitionsData, day[this.props.tag]);
          this.renderSpaceLineElement(content, timesettings, definitionsData, day["firespace"]);
        }
      }
    }
    return content;
  }

  render() 
  {
    return (
      <div className="spacecolumn">
        <SpaceLineHeader id={this.props.id} name={this.props.name} spaceScrollLeft={this.props.spaceScrollLeft}/>
        {this.renderSpaceLine(this.props.timesettings, this.props.serverdata, this.props.selectedDay)}
      </div>
    );
  }
}
export default SpaceColumn;