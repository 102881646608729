import React from 'react';
import './Admin.css'
import {findObjectById, findIdInObject, getStartTimeWithOffset} from '../Helpers'
import AdminNavigation from './AdminNavigation';
import AdminCourseForm from './AdminCourseForm';
import AdminCourseTable from './AdminCourseTable';
import AdminTimeForm from './AdminTimeForm';
import AdminTimesTable from './AdminTimeTable';
import AdminRegistrationTable from './AdminRegistrationTable';
import AdminRegistrationForm from './AdminRegistrationForm';
import AdminError from './AdminError';

class AdminArea extends React.Component {
  elementerrorCourse = {
    title: "",
    person: "",
    lang: "",
    level: "",
    tool: "",
    participants: "",
    description: "",
  }

  elementerrorDate =  {
    starttime: "",
    duration: "",
    day: "",
    space: "",
    course: "",
  }

  elementerrorRegistration = {
    name: "",
    email: "",
    personinfo: "",
    ticket: "",
    title: "",
    lang: "",
    level: "",
    tool: "",
    participants: "",
    description: "",
    knowledge: "",
    duration: "",
    timeinfo: "",
    extinfo: "",
  }

  formStateCourse = {
    id: '',
    title: "",
    person: "",
    lang: 1,
    level: 1,
    tool: "",
    participants: 0,
    description: "",
    registration: "",
    mode: "submit",
    showCancel: false,
    updating: false,
    readonly: false,
    elementerror: this.elementerrorCourse
  }

  formStateDate = {
    id: '',
    starttime: "08:00:00",
    duration: "",
    day: 1,
    space: 1,
    course: this.props.courses.length !== 0?this.props.courses[0].id:0,
    mode: "submit",
    showCancel: false,
    updating: false,
    readonly: false,
    elementerror: this.elementerrorDate
  }

  formStateRegistration = {
    id: '',
    name: "",
    email: "",
    personinfo: "",
    ticket: 0,
    title: "",
    lang: 1,
    level: 1,
    tool: "",
    participants: 0,
    description: "",
    knowledge: "",
    duration: "",
    timeinfo: "",
    extinfo: "",
    mode: "submit",
    editState: 0,
    showCancel: false,
    updating: false,
    readonly: false,
    elementerror: this.elementerrorRegistration
  }

  state = {
    mode: "courses",
    errormessage: "",
    formStateCourse: this.formStateCourse,
    formStateDate: this.formStateDate,
    formStateRegistration: this.formStateRegistration
  };

  resetFormState = () => {
    this.setState({
      errormessage: "",
      formStateCourse: this.formStateCourse,
      formStateDate: this.formStateDate,
      formStateRegistration: this.formStateRegistration
    });
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  checkForm = () => {
    this.resetCheck();
    let elementerror = {elementerrorCourse: 
                        { 
                          title: "",
                          person: "",
                          lang: "",
                          level: "",
                          tool: "",
                          participants: "",
                          description: "",
                        },
                        elementerrorDate: 
                        {
                          starttime: "",
                          duration: "",
                          day: "",
                          space: "",
                          course: "",
                        },
                        elementerrorRegistration:
                        {
                          name: "",
                          email: "",
                          personinfo: "",
                          ticket: "", 
                          title: "",
                          lang: "",
                          level: "",
                          tool: "",
                          participants: "",
                          description: "",
                          knowledge: "",
                          duration: "",
                          timeinfo: "",
                          extinfo: "",
                        },
                        errormessage: ""};

    let notEmpty = this.checkFormNotEmpty(elementerror);
    let greaterZero = this.checkValueGreaterZero(elementerror);
    let timeAvailable = this.checkTimeIsAvailable(elementerror);
    this.setErrorState(elementerror);

    return notEmpty && greaterZero && timeAvailable;
  }

  resetCheck = () => {
    if(this.state.mode === "courses")
    {
      this.setState({
        formStateCourse: { 
          ...this.state.formStateCourse,
          elementerror: this.elementerrorCourse,
        }
      })
    }
    else if(this.state.mode === "dates")
    {
      this.setState({
        formStateDate: { 
          ...this.state.formStateDate,
          elementerror: this.elementerrorDate,
        }
      })
    }
    else if(this.state.mode === "registrations")
    {
      this.setState({
        formStateRegistration: { 
          ...this.state.formStateRegistration,
          elementerror: this.elementerrorRegistration,
        }
      })
    }
  };


  checkFormNotEmpty = (elementerror) => {
    let status = true;
    let emptyerror = "This field is required."
    if(this.state.mode === "courses")
    {
      if(this.state.formStateCourse.title === "" || 
         this.state.formStateCourse.person === "" || 
         this.state.formStateCourse.lang === "" || 
         this.state.formStateCourse.level === "" ||  
         this.state.formStateDate.participants === "" || 
         this.state.formStateDate.description === "")
      {
        elementerror.elementerrorCourse.title = this.state.formStateCourse.title === ""? emptyerror:"";
        elementerror.elementerrorCourse.person = this.state.formStateCourse.person === ""?emptyerror:"";
        elementerror.elementerrorCourse.lang = this.state.formStateCourse.lang === ""?emptyerror:"";
        elementerror.elementerrorCourse.level = this.state.formStateCourse.level === ""?emptyerror:"";
        elementerror.elementerrorCourse.participants = this.state.formStateCourse.participants === ""?emptyerror:"";
        elementerror.elementerrorCourse.description = this.state.formStateCourse.description === ""?emptyerror:"";
        status = false;
      }    
    }
    else if(this.state.mode === "dates")
    {
      if(this.state.formStateDate.starttime === "" || 
         this.state.formStateDate.duration === "" || 
         this.state.formStateDate.day === "" ||
         this.state.formStateDate.space === "" || 
         this.state.formStateDate.course ===  "")
      {
        elementerror.elementerrorDate.starttime = this.state.formStateDate.starttime === ""?emptyerror:"";
        elementerror.elementerrorDate.duration = this.state.formStateDate.duration === ""?emptyerror:"";
        elementerror.elementerrorDate.day = this.state.formStateDate.day === ""?emptyerror:"";
        elementerror.elementerrorDate.space = this.state.formStateDate.space === ""?emptyerror:"";
        elementerror.elementerrorDate.course = this.state.formStateDate.course === ""?emptyerror:"";
        status = false;
      }
    }
    else if(this.state.mode === "registrations")
    {
      if(this.state.formStateRegistration.name === "" || 
         this.state.formStateRegistration.email === "" || 
         this.state.formStateRegistration.title === "" || 
         this.state.formStateRegistration.lang === "" || 
         this.state.formStateRegistration.level === "" || 
         this.state.formStateRegistration.participants === "" || 
         this.state.formStateRegistration.description === "" || 
         this.state.formStateRegistration.knowledge === "" || 
         this.state.formStateRegistration.duration === "" || 
         this.state.formStateRegistration.timeinfo === "" || 
         this.state.formStateRegistration.extinfo === "")
      {

        elementerror.elementerrorRegistration.name = this.state.formStateRegistration.name === ""?emptyerror:"";
        elementerror.elementerrorRegistration.email = this.state.formStateRegistration.email === ""?emptyerror:"";
        elementerror.elementerrorRegistration.title = this.state.formStateRegistration.title === ""?emptyerror:"";
        elementerror.elementerrorRegistration.lang = this.state.formStateRegistration.lang === ""?emptyerror:"";
        elementerror.elementerrorRegistration.level = this.state.formStateRegistration.level === ""?emptyerror:"";
        elementerror.elementerrorRegistration.participants = this.state.formStateRegistration.participants === ""?emptyerror:"";
        elementerror.elementerrorRegistration.description = this.state.formStateRegistration.description === ""?emptyerror:"";
        elementerror.elementerrorRegistration.knowledge = this.state.formStateRegistration.knowledge === ""?emptyerror:"";
        elementerror.elementerrorRegistration.duration = this.state.formStateRegistration.duration === ""?emptyerror:"";
        elementerror.elementerrorRegistration.timeinfo = this.state.formStateRegistration.timeinfo === ""?emptyerror:"";
        elementerror.elementerrorRegistration.extinfo = this.state.formStateRegistration.extinfo === ""?emptyerror:"";
        status = false;
      }
    }
    return status;
  };

  checkValueGreaterZero = (elementerror) => {
    let status = true;
    let zeroerror = "This value must be greater zero."
    
    if(this.state.mode === "dates")
    {
      if(Number(this.state.formStateDate.duration) === 0)
      {
        elementerror.elementerrorDate.duration = zeroerror;
        status = false;
      }
    }
    else if(this.state.mode === "registrations")
    {
      if(Number(this.state.formStateRegistration.duration) === 0)
      {
        elementerror.elementerrorRegistration.duration = zeroerror;
        status = false;
      }
    }
    return status;
  };


  checkTimeIsAvailable = (elementerror) => {
    let status = true;
    let blockederror = "Blocked by an other date"
    if(this.state.mode === "dates")
    {
      const { formStateDate } = this.state;
      const starttimeArr = formStateDate.starttime.split(":");
      const startminute = getStartTimeWithOffset(starttimeArr[0]) * 60 +  Number(starttimeArr[1]);
      const endminute = Number(startminute) + Number(formStateDate.duration);;
      let blockDate = {};
      this.props.dates.forEach(date =>
      {
        if(Number(formStateDate.day) === Number(date.day) && Number(formStateDate.space) === Number(date.space) && Number(formStateDate.id) !== Number(date.id))
        {
          const curstarttimeArr = date.starttime.split(":");  
          const curstartminute = getStartTimeWithOffset(curstarttimeArr[0]) * 60 +  Number(curstarttimeArr[1]);
          const curendminute = Number(curstartminute) + Number(date.duration);

          if((curstartminute >= startminute && curstartminute < endminute) ||
            (curendminute > startminute && curendminute <= endminute) || 
            (startminute >= curstartminute && startminute < curendminute) ||
            (endminute > curstartminute && endminute < curendminute))
          {
            if(status === true)
            {
              status = false;
              blockDate = date;
            }
          }
        }
      });

      if(status === false)
      {
        elementerror.elementerrorDate.starttime = blockederror;
        elementerror.elementerrorDate.duration = blockederror;
        elementerror.elementerrorDate.day = blockederror;
        elementerror.elementerrorDate.space = blockederror;
        elementerror.errormessage = "Date is blocked by: " + blockDate.starttime + ", " + blockDate.duration + "min., " + findObjectById(this.props.courses, blockDate.course).title
      }
    }
    return status;
  }


  setErrorState = (elementerror) => {
    if(this.state.mode === "courses")
    {
      this.setState({
        formStateCourse: { 
          ...this.state.formStateCourse,
          elementerror: elementerror.elementerrorCourse,
        },
        errormessage: elementerror.errormessage,
      })
    }
    else if(this.state.mode === "dates")
    {
      this.setState({
        formStateDate: { 
          ...this.state.formStateDate,
          elementerror: elementerror.elementerrorDate,
        },
        errormessage: elementerror.errormessage,
      })
    }
    else if(this.state.mode === "registrations")
    {
      this.setState({
        formStateRegistration: { 
          ...this.state.formStateRegistration,
          elementerror: elementerror.elementerrorRegistration,
        },
        errormessage: elementerror.errormessage,
      })
    }
  }


  crossEditRegistration(formStateCourse)
  {
    if(formStateCourse.registration !== "")
    {
      let registration = findObjectById(this.props.registrations, formStateCourse.registration)
      if(registration !== undefined)
      {
        registration.title = formStateCourse.title;
        registration.name = formStateCourse.person;
        registration.lang = formStateCourse.lang;
        registration.level = formStateCourse.level;
        registration.tool = formStateCourse.tool;
        registration.participants = formStateCourse.participants;
        registration.description = formStateCourse.description;
        this.props.editRegistration(registration);
      }

    }
  }

  crossEditCourse(formStateRegistration, remove)
  {
    if(formStateRegistration.id !== "")
    {
      let course = findIdInObject(this.props.courses, "registration", formStateRegistration.id)
      if(course !== undefined)
      {
        course.title = formStateRegistration.title;
        course.person = formStateRegistration.name;
        course.lang = formStateRegistration.lang;
        course.level = formStateRegistration.level;
        course.tool = formStateRegistration.tool;
        course.participants = formStateRegistration.participants;
        course.description = formStateRegistration.description;
        course.registration = remove?"":formStateRegistration.id;

        this.props.editCourse(course);
      }

    }
  }

  onChange = event => {
    let result = event.target.value;
    if( event.target.name === "duration" || event.target.name === "participants")
    {
      result = event.target.value.replace(/\D/g, '');
      if( result !== "" )
      {
        result = Number(result);
      }
    }
    if(this.state.mode === "courses")
    {
      this.setState({
        formStateCourse: {
            ...this.state.formStateCourse,
            [event.target.name]: result,
        }
      });
    }
    else if(this.state.mode === "dates")
    {
      this.setState({
        formStateDate: {
            ...this.state.formStateDate,
            [event.target.name]: result,
        }
      });
    }
    else if(this.state.mode === "registrations")
    {
      this.setState({
        formStateRegistration: {
            ...this.state.formStateRegistration,
            [event.target.name]: result,
        }
      });
    }
  };

  onChangeTimePicker = time => {
    const date = new Date(time);
    const timestring = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
    this.setState({
      formStateDate: {
          ...this.state.formStateDate,
          starttime: timestring,
      }
    });
  }

  onSubmit = event => {
    const { formStateCourse, formStateDate, formStateRegistration} = this.state;
    event.preventDefault();

    if(this.state.mode === "courses")
    {
      if (formStateCourse.mode === "submit")
      {
        if(this.checkForm())
        {
          this.props.submitCourse(formStateCourse);
          this.resetFormState();
        }
      } 
      else if (formStateCourse.mode === "edit") 
      {
        if(this.checkForm())
        {
          this.props.editCourse(formStateCourse);
          this.crossEditRegistration(formStateCourse);
          this.resetFormState();
        }
      }
      else if (formStateCourse.mode === "delete") 
      {
        this.props.deleteCourse(formStateCourse);
        this.resetFormState();
      }
    }
    else if(this.state.mode === "dates")
    {
      if (formStateDate.mode === "submit")
      {
        if(this.checkForm())
        {
          this.props.submitDate(formStateDate);
          this.resetFormState();
        }
      } 
      else if (formStateDate.mode === "edit") 
      {
        if(this.checkForm())
        {
          this.props.editDate(formStateDate);
          this.resetFormState();
        }
      }
      else if (formStateDate.mode === "delete") 
      {
        this.props.deleteDate(formStateDate);
        this.resetFormState();
      }
    }
    else if(this.state.mode === "registrations")
    {
      if (formStateRegistration.mode === "submit")
      {
        if(this.checkForm())
        {
          this.props.submitRegistration(formStateRegistration);
          this.resetFormState();
        }
      } 
      else if (formStateRegistration.mode === "edit") 
      {
        if(this.checkForm())
        {
          this.props.editRegistration(formStateRegistration);
          this.crossEditCourse(formStateRegistration, false);
          this.resetFormState();
        }
      }
      else if (formStateRegistration.mode === "delete") 
      {
        this.props.deleteRegistration(formStateRegistration);
        this.crossEditCourse(formStateRegistration, true);
        this.resetFormState();
      }
    }
  };

  onCancel = () => {
    if(this.state.mode === "courses")
    {
      const { formStateCourse } = this.state;
      this.props.cancelCourse(formStateCourse);
      this.resetFormState();
    }
    else if(this.state.mode === "dates")
    {
      const { formStateDate } = this.state;
      this.props.cancelDate(formStateDate);
      this.resetFormState();
    }
    else if(this.state.mode === "registrations")
    {
      const { formStateRegistration } = this.state;
      this.props.cancelRegistration(formStateRegistration);
      this.resetFormState();
    }
  };

  handleGotoCourse = course => {
    this.handleChangeMode("courses")
    if(course.id === "")
    {
      this.setState({
        formStateCourse: {...this.formStateCourse, ...course,  showCancel: true, readonly: true}
      });
    }
    else
    {
      this.setState({
        formStateCourse: { ...course, mode: "edit", showCancel: true, updating: true, elementerror: this.elementerrorCourse }
      });
      this.props.updateCourse(course);
    }
    this.scrollToTop();
  };

  handleGotoDate = date => {
    
    this.handleChangeMode("dates")
    if(date.id === "")
    {
      this.setState({
        formStateDate: {...this.formStateDate, ...date }
      });

    }
    else
    {
      this.setState({
        formStateDate: { ...date, mode: "edit", showCancel: true, updating: true, elementerror: this.elementerrorDate }
      });
      this.props.updateDate(date);
    }
    this.scrollToTop();
  };

  handleGotoRegistration = registration => {
    
    this.handleChangeMode("registrations")
    if(registration.id === "")
    {
      this.setState({
        formStateRegistration: {...this.formStateRegistration, ...registration }
      });

    }
    else
    {
      this.setState({
        formStateRegistration: { ...registration, mode: "edit", showCancel: true, updating: true, elementerror: this.elementerrorRegistration }
      });
      this.props.updateRegistration(registration);
    }
    this.scrollToTop();
  };


  handleUpdateEditCourse = key => {
    if(this.state.formStateCourse.updating === false)
    {
      this.setState({
        formStateCourse: { ...this.props.courses[key], mode: "edit", showCancel: true, updating: true, elementerror: this.elementerrorCourse }
      });
      this.props.updateCourse(this.props.courses[key]);
    }
    this.scrollToTop();
  };

  handleUpdateDeleteCourse = key => {
    if(this.state.formStateCourse.updating === false)
    {
      this.setState({
        formStateCourse: { ...this.props.courses[key], mode: "delete", showCancel: true, updating: true,  readonly: true, elementerror: this.elementerrorCourse }
      });
      this.props.updateCourse(this.props.courses[key]);
    }
    this.scrollToTop();
  };


  handleUpdateEditDate = key => {
    if(this.state.formStateDate.updating === false)
    {
      this.setState({
        formStateDate: { ...this.props.dates[key], mode: "edit", showCancel: true, updating: true, elementerror: this.elementerrorDate }
      });
      this.props.updateDate(this.props.dates[key]);
    }
    this.scrollToTop();
  };

  handleUpdateDeleteDate = key => {
    if(this.state.formStateDate.updating === false)
    {
      this.setState({
        formStateDate: { ...this.props.dates[key], mode: "delete", showCancel: true, updating: true,  readonly: true, elementerror: this.elementerrorDate }
      });
      this.props.updateDate(this.props.dates[key]);
    }
    this.scrollToTop(); 
  };

  handleMarkRegistration = key => {
      let registrationElement = this.props.registrations[key];
      registrationElement.editState  = registrationElement.editState === 1?0:1;

      if(registrationElement !== undefined)
      {
        this.props.editRegistration(registrationElement);
      }
  };

  handleUpdateEditRegistration = key => {
    if(this.state.formStateRegistration.updating === false)
    {
      this.setState({
        formStateRegistration: { ...this.props.registrations[key], mode: "edit", showCancel: true, updating: true, elementerror: this.elementerrorRegistration }
      });
      this.props.updateRegistration(this.props.registrations[key]);
    }
    this.scrollToTop();
  };

  handleUpdateDeleteRegistration = key => {
    if(this.state.formStateRegistration.updating === false)
    {
      this.setState({
        formStateRegistration: { ...this.props.registrations[key], mode: "delete", showCancel: true, updating: true,  readonly: true, elementerror:this.elementerrorRegistration }
      });
      this.props.updateRegistration(this.props.registrations[key]);
    }
    this.scrollToTop();
  };


  
  handleChangeMode = mode => {
    this.setState({
      mode: mode
    });
    this.onCancel();
  }

  selectByMode = (definitions, courses, dates, registrations) => {
    const {mode, formStateCourse, formStateDate, formStateRegistration} = this.state;

    if(mode === "courses")
    {
      return (
        <div>
          <AdminCourseForm
            definitions={definitions}
            formState={formStateCourse}
            onChange={this.onChange}
            onSubmit={this.onSubmit}
            onCancel={this.onCancel}
          />
          <AdminError value={this.state.errormessage}/>
          <AdminCourseTable
            definitions={definitions}
            courses={courses}
            dates={dates}
            registrations={registrations}
            gotoRegistration={this.handleGotoRegistration}
            gotoDate={this.handleGotoDate}
            updateCourse={this.handleUpdateEditCourse}
            deleteCourse={this.handleUpdateDeleteCourse}
          />
        </div>
      )
    }
    else if(mode === "dates")
    {
      return (
        <div>
          <AdminTimeForm
            definitions={definitions}
            courses={courses}
            formState={formStateDate}
            onChange={this.onChange}
            onChangeTimePicker={this.onChangeTimePicker}
            onSubmit={this.onSubmit}
            onCancel={this.onCancel}
          />
          <AdminError value={this.state.errormessage}/>
          <AdminTimesTable
            definitions={definitions}
            courses={courses}
            dates={dates}
            gotoCourse={this.handleGotoCourse}
            updateDate={this.handleUpdateEditDate}
            deleteDate={this.handleUpdateDeleteDate}
          />
        </div>
      )
    }
    else if(mode === "registrations")
    {
      return (
        <div>
          <AdminRegistrationForm
            definitions={definitions}
            courses={courses}
            formState={formStateRegistration}
            onChange={this.onChange}
            onSubmit={this.onSubmit}
            onCancel={this.onCancel}
          />
          <AdminError value={this.state.errormessage}/>
          <AdminRegistrationTable
            definitions={definitions}
            registrations={registrations}
            courses={courses}
            gotoCourse={this.handleGotoCourse}
            markRegistration={this.handleMarkRegistration}
            updateRegistration={this.handleUpdateEditRegistration}
            deleteRegistration={this.handleUpdateDeleteRegistration}
          />
        </div>
      )
    }
  }

  render() {
    return (
      <div>
        <AdminNavigation mode={this.state.mode} changeMode={this.handleChangeMode}/>
        {this.selectByMode(this.props.definitions, this.props.courses, this.props.dates, this.props.registrations)}
      </div>
    );
  }
}

export default AdminArea;