import React from 'react';
import './Workshop.css';

class WorkshopTitle extends React.Component 
{
  render() 
  {
    return (
        <div className="workshop-header">Workshop Registration</div>
    );
  }
}
export default WorkshopTitle;