import React from 'react';
import './Admin.css'
import AdminBaseTable from './AdminBaseTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faFileCircleMinus, faCircleDot, faCircleLeft } from '@fortawesome/free-solid-svg-icons';
import {findObjectById, findIdInObject} from '../Helpers'

class AdminCourseTable extends AdminBaseTable
{
  hasRegistration = (registrations, id) => {
    if(id !== null)
    {
      if(findObjectById(registrations, id) !== undefined)
      {
        return true;
      }
    }
    return false;
  }

  hasDate = (dates, id) => {
    if(findIdInObject(dates, "course", id) !== undefined)
    {
      return true;
    }
    return false;
  }

  getDateObject = (dates, id) => {
    let object = findIdInObject(dates, "course", id)
    if(object !== undefined)
    {
      return object;
    }
    return {id: "", course: id};
  }

  getElement = () =>
  {
    return this.props.courses;
  }

  compareFunction = (a, b) => 
  {
    const element = this.state.compareType;
    if(element === "title" || element === "person" || element === "tool" || element === "description" )
    {
      if(this.state.isAscending)
        return b[element].localeCompare(a[element])
      else
        return a[element].localeCompare(b[element])
    }
    else if(element === "id" || element === "lang" || element === "level" || element === "participants" )
    {
      if(this.state.isAscending)
        return b[element] - a[element]
      else
        return a[element] - b[element]
    }
    return 0;
  }

  getHeader = () =>
  {
    return (
      <div className="admin-row">
        <div className="admin-column admin-column-id" onClick={() => this.setCompare("id")}>ID</div>
        <div className="admin-column admin-column-medium" onClick={() => this.setCompare("title")}>Title</div>
        <div className="admin-column admin-column-medium" onClick={() => this.setCompare("person")}>Person</div>
        <div className="admin-column admin-column-small" onClick={() => this.setCompare("lang")}>Language</div>
        <div className="admin-column admin-column-small" onClick={() => this.setCompare("level")}>Level</div>
        <div className="admin-column admin-column-small" onClick={() => this.setCompare("tool")}>Tool</div>
        <div className="admin-column admin-column-extrasmall" onClick={() => this.setCompare("participants")}>Part.</div>
        <div className="admin-column admin-column-large" onClick={() => this.setCompare("description")}>Description</div>
        <div className="admin-column admin-column-options">Options</div>
      </div>
    );
  }

  getTableElements = () =>
  {
    return[
      this.getElement().map((course, key) => {
        return (
          <div className={`admin-row ${key % 2 === 0 ?(course.updating ?"admin-updating-even":"admin-row-even"):(course.updating ?"admin-updating-odd":"admin-row-odd")}`} key={course.id}>
            <div className="admin-column admin-column-id">{course.id}</div>
            <div className="admin-column admin-column-medium">{course.title}</div>
            <div className="admin-column admin-column-medium">{course.person}</div>
            <div className="admin-column admin-column-small">{findObjectById(this.props.definitions.languages, course.lang).name}</div>
            <div className="admin-column admin-column-small">{findObjectById(this.props.definitions.levels, course.level).name}</div>
            <div className="admin-column admin-column-small">{course.tool}</div>
            <div className="admin-column admin-column-extrasmall">{course.participants}</div>
            <div className="admin-column admin-column-large">{course.description}</div>
            <div className="admin-column admin-column-options">
              <button 
                 className={"admin-icon" + (this.hasRegistration(this.props.registrations, course.registration)?" admin-icongreen":" admin-iconred")}
                 onClick={() => this.hasRegistration(this.props.registrations, course.registration)?this.props.gotoRegistration(findObjectById(this.props.registrations, course.registration)):""}
              >
                <FontAwesomeIcon icon={ faCircleLeft }/>
              </button>  
              <button 
                 className={"admin-icon" + (this.hasDate(this.props.dates, course.id)?" admin-icongreen":" admin-iconred")}
                 onClick={() => this.props.gotoDate(this.getDateObject(this.props.dates, course.id))}
              >
                <FontAwesomeIcon icon={ faCircleDot }/>
              </button>  
              <button
                  className="admin-icon"
                  onClick={() => this.props.updateCourse(key)}
              >
                <FontAwesomeIcon icon={ faEdit }/>
              </button>
              <button 
                 className="admin-icon"
                 onClick={() => this.props.deleteCourse(key)}
              >
                <FontAwesomeIcon icon={ faFileCircleMinus }/>
              </button>
            </div>
          </div>
        );
      })
    ]
  }
}
export default AdminCourseTable;