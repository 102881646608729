import React from 'react';
import './Workshop.css';
import AdminInput from '../admin/AdminInput';
import AdminTextarea from '../admin/AdminTextarea';
import AdminSelect from '../admin/AdminSelect';
import AdminCheckbox from '../admin/AdminCheckbox';


class WorkshopForm extends React.Component 
{
  render() 
  {
    let userLevelList = [];
    if(this.props.definitions.levels !== undefined)
    {
      userLevelList = [...this.props.definitions.levels].slice(0,-1)
    }
    return (
        <form className="workshop-form" onSubmit={this.props.onSubmit}>
          <fieldset className="workshop-fieldset">
            <div className="workshop-subheader">Personal information</div>
            <AdminInput
              prefix="workshop"
              name="name"
              label="Your name"
              type="text"
              value={this.props.formState.name}
              onChange={this.props.onChange}
              readonly={this.props.formState.readonly}
              haserror={this.props.formState.elementerror.name !== ""}
              errormsg={this.props.formState.elementerror.name}
            />
            <AdminInput
              prefix="workshop"
              name="email"
              label="Enter E-mail"
              type="text"
              value={this.props.formState.email}
              onChange={this.props.onChange}
              readonly={this.props.formState.readonly}
              haserror={this.props.formState.elementerror.email !== ""}
              errormsg={this.props.formState.elementerror.email}
            />

            <AdminInput
              prefix="workshop"
              name="emailconfirm"
              label="Confirm E-mail"
              type="text"
              value={this.props.formState.emailconfirm}
              onChange={this.props.onChange}
              readonly={this.props.formState.readonly}
              haserror={this.props.formState.elementerror.emailconfirm !== ""}
              errormsg={this.props.formState.elementerror.emailconfirm}
            />
            <AdminTextarea
              prefix="workshop"
              name="personinfo"
              label="Links to social media / videos of skills"
              sublabel="Help us to understand what awesome stuff you do."
              value={this.props.formState.personinfo}
              onChange={this.props.onChange}
              readonly={this.props.formState.readonly}
              haserror={this.props.formState.elementerror.personinfo !== ""}
              errormsg={this.props.formState.elementerror.personinfo}
            />

            <AdminSelect
              prefix="workshop"
              name="ticket"
              label="Do you already have a ticket?"
              value={this.props.formState.ticket}
              list={this.props.definitions.yesno}
              onChange={this.props.onChange}
              readonly={this.props.formState.readonly}
              haserror={this.props.formState.elementerror.ticket !== ""}
              errormsg={this.props.formState.elementerror.ticket}
            />
            <div className="workshop-subheader">Workshop information</div>
            <AdminInput
              prefix="workshop"
              name="title"
              label="Workshop Name"
              type="text"
              value={this.props.formState.title}
              onChange={this.props.onChange}
              readonly={this.props.formState.readonly}
              haserror={this.props.formState.elementerror.title !== ""}
              errormsg={this.props.formState.elementerror.title}
            />
            <AdminSelect
              prefix="workshop"
              name="lang"
              label="Workshop language"
              value={this.props.formState.lang}
              list={this.props.definitions.languages}
              listitem={"fullname"}
              onChange={this.props.onChange}
              readonly={this.props.formState.readonly}
              haserror={this.props.formState.elementerror.lang !== ""}
              errormsg={this.props.formState.elementerror.lang}
            />
            <AdminTextarea
              prefix="workshop"
              name="description"
              label="Workshop description"
              value={this.props.formState.description}
              onChange={this.props.onChange}
              readonly={this.props.formState.readonly}
              haserror={this.props.formState.elementerror.description !== ""}
              errormsg={this.props.formState.elementerror.description}
            />
            <AdminInput
              prefix="workshop"
              name="tool"
              label="Which prop, toy or tool is the workshop for?"
              type="text"
              value={this.props.formState.tool}
              onChange={this.props.onChange}
              readonly={this.props.formState.readonly}
              haserror={this.props.formState.elementerror.tool !== ""}
              errormsg={this.props.formState.elementerror.tool}
            />
            <AdminInput
              prefix="workshop"
              name="participants"
              label="Maximum number of participants?"
              sublabel="0 for no limit"
              type="text"
              value={this.props.formState.participants}
              onChange={this.props.onChange}
              readonly={this.props.formState.readonly}
              haserror={this.props.formState.elementerror.participants !== ""}
              errormsg={this.props.formState.elementerror.participants}
            />

            <AdminSelect
              prefix="workshop"
              name="level"
              label="What is the difficulty level?"
              value={this.props.formState.level}
              list={userLevelList}
              onChange={this.props.onChange}
              readonly={this.props.formState.readonly}
              haserror={this.props.formState.elementerror.level !== ""}
              errormsg={this.props.formState.elementerror.level}
            />  

            <AdminTextarea
              prefix="workshop"
              name="knowledge"
              label="Do the participants need prior knowledge, such as certain tricks or basics?"
              value={this.props.formState.knowledge}
              onChange={this.props.onChange}
              readonly={this.props.formState.readonly}
              haserror={this.props.formState.elementerror.knowledge !== ""}
              errormsg={this.props.formState.elementerror.knowledge}
            />
            <AdminSelect
              prefix="workshop"
              name="duration"
              label="Workshop Length"
              value={this.props.formState.duration}
              list={[{id: 45, name: "45"},{id: 90, name: "90"}]}
              onChange={this.props.onChange}
              readonly={this.props.formState.readonly}
              haserror={this.props.formState.elementerror.duration !== ""}
              errormsg={this.props.formState.elementerror.duration}
            />
            <AdminTextarea
              prefix="workshop"
              name="timeinfo"
              label="When would you like to give your workshop?"
              sublabel="In the morning? Not on a certain day? Not at the same time as…?"
              value={this.props.formState.timeinfo}
              onChange={this.props.onChange}
              readonly={this.props.formState.readonly}
              haserror={this.props.formState.elementerror.timeinfo !== ""}
              errormsg={this.props.formState.elementerror.timeinfo}
            />
            <AdminTextarea
              prefix="workshop"
              name="extinfo"
              label="What are the basic requirements for your workshop placement?"
              sublabel="Do you need somethingh like carpet, tent, sun / rain cover, ..."
              value={this.props.formState.extinfo}
              onChange={this.props.onChange}
              readonly={this.props.formState.readonly}
              haserror={this.props.formState.elementerror.extinfo !== ""}
              errormsg={this.props.formState.elementerror.extinfo}
            />

            <div className="workshop-subheader">Privacy policy</div>
            <AdminCheckbox
              prefix="workshop"
              name="check"
              legend="Workshops are lead on a volunteer basis. All of our teachers will receive a token of appreciation."
              label="I agree to the privacy policy."
              value={this.props.formState.check}
              onChange={this.props.onChange}
              readonly={this.props.formState.readonly}
              haserror={this.props.formState.elementerror.check !== ""}
              errormsg={this.props.formState.elementerror.check}
            />
          </fieldset>
          <button className="workshop-button" type="submit">Submit</button>
        </form>
    );
  }
}
export default WorkshopForm;