import React from 'react';
import './Admin.css'

class AdminError extends React.Component 
{
  render() 
  {
    return (
        <div className="admin-error">{this.props.value}</div>
    );
  }
}

export default AdminError;