import React from 'react';
import './Admin.css'

class AdminBaseTable extends React.Component 
{
  state = {
    compareType: "",
    isAscending: false,
  }

  setCompare = (type) =>
  {
    this.setState(prevState => ({
      isAscending: type === prevState.compareType?!prevState.isAscending:false,
      compareType: type
    })); 
  }

  getElement = () =>
  {
    throw new Error("Abstract Method getHeader() has no implementation");
  }

  compareFunction = (a, b) => 
  {
    throw new Error("Abstract Method compareFunction() has no implementation");
  }

  getHeader = () =>
  {
    throw new Error("Abstract Method getHeader() has no implementation");
  }

  getTableElements = () =>
  {
    throw new Error("Abstract Method getTableElements() has no implementation");
  }

  render() 
  { 
    this.getElement().sort(this.compareFunction);
    return (
      <div className="admin-table">
        <div className="admin-table-header">
            {this.getHeader()}
         </div>
         <div className="admin-table-body">
            {this.getTableElements()}
        </div>
      </div>
    );
  };
}
export default AdminBaseTable;