import React from 'react';
import './Board.css'

class TimeLineHeader extends React.Component 
{
  render() 
  {
    return (
      <div className="timelineheader"></div>
    );
  }
}
export default TimeLineHeader;