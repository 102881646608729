import React from 'react';
import './Board.css'

class ArrowSvg extends React.Component 
{
  render() 
  {
    return (
      <svg
        className={this.props.className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 551 1024"
      >
        {this.props.direction === "left"
          ?<path d="M445.44 38.183L-2.53 512l447.97 473.817 85.857-81.173-409.6-433.23v81.172l409.6-433.23L445.44 38.18z" />
          :<path d="M105.56 985.817L553.53 512 105.56 38.183l-85.857 81.173 409.6 433.23v-81.172l-409.6 433.23 85.856 81.174z" />
        }
      </svg>
    );
  }
}
export default ArrowSvg;