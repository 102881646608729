import React from 'react';
import './Board.css'
import {findObjectById} from '../Helpers'
import ArrowButton from './ArrowButton'

class ConfigLine extends React.Component 
{
  isLimitDay(selectedDay, limitDay)
  {
    if(selectedDay === limitDay)
    {
      return true;
    }
    return false;
  }

  render() 
  {
    let currentDayName = "";
    let definitionsData = this.props.serverdata.definitions;
    if(definitionsData !== undefined)
    {
      let dayNames = definitionsData.days;
      if(dayNames !== undefined)
      {
        currentDayName = findObjectById(dayNames, this.props.selectedDay).name;
      }
    }

    return (
      <div className="configline">
        <ArrowButton key="left" className="datenav datenavleft" subClassName="datenavicon" direction="left" style={{visibility: this.isLimitDay(this.props.selectedDay, this.props.daysettings.firstday)?"hidden":"visible" }} onClickButton={() => this.props.onClickPreviousDay()}/>
        <div className='dateelement textworkspacenames'>{currentDayName}</div>
        <ArrowButton key="right" className="datenav" subClassName="datenavicon" direction="right" style={{visibility: this.isLimitDay(this.props.selectedDay, this.props.daysettings.lastday)?"hidden":"visible" }} onClickButton={() => this.props.onClickNextDay()}/>
      </div>
    );
  }
}

export default ConfigLine;