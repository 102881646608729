import React from 'react';
import './Admin.css'

class AdminNavigation extends React.Component 
{
  render() 
  {
    return (
      <div className="admin-navigation">
         <button className={"admin-navbutton" + (this.props.mode === "registrations"?" admin-navbutton-selected":"")} onClick={() => this.props.changeMode("registrations")}>{"Registrations"}</button>  
         <button className={"admin-navbutton" + (this.props.mode === "courses"?" admin-navbutton-selected":"")} onClick={() => this.props.changeMode("courses")}>{"Courses"}</button>  
         <button className={"admin-navbutton" + (this.props.mode === "dates"?" admin-navbutton-selected":"")} onClick={() => this.props.changeMode("dates")}>{"Timetable"}</button>      
      </div>
    );
  }
}

export default AdminNavigation;