import React from 'react';
import './Admin.css'
import AdminBaseForm from './AdminBaseForm';
import AdminInput from './AdminInput';
import AdminTimePicker from './AdminTimePicker';
import AdminSelect from './AdminSelect';

class AdminTimeForm extends AdminBaseForm 
{
  getFieldset = () =>
  {
    return (
        <fieldset className="admin-fieldset">
          <AdminTimePicker
            prefix="admin"
            name="starttime"
            label="Starttime"
            type="text"
            value={this.props.formState.starttime}
            onChange={this.props.onChangeTimePicker}
            readonly={this.props.formState.readonly}
            haserror={this.props.formState.elementerror.starttime !== ""}
            errormsg={this.props.formState.elementerror.starttime}
          />
          <AdminInput
            prefix="admin"
            name="duration"
            label="Duration"
            type="text"
            value={this.props.formState.duration}
            onChange={this.props.onChange}
            readonly={this.props.formState.readonly}
            haserror={this.props.formState.elementerror.duration !== ""}
            errormsg={this.props.formState.elementerror.duration}
          />
          <AdminSelect
            prefix="admin"
            name="day"
            label="Day"
            value={this.props.formState.day}
            list={this.props.definitions.days}
            onChange={this.props.onChange}
            readonly={this.props.formState.readonly}
            haserror={this.props.formState.elementerror.day !== ""}
            errormsg={this.props.formState.elementerror.day}
          />
          <AdminSelect
            prefix="admin"
            name="space"
            label="Space"
            value={this.props.formState.space}
            list={this.props.definitions.spacenames}
            onChange={this.props.onChange}
            readonly={this.props.formState.readonly}
            haserror={this.props.formState.elementerror.space !== ""}
            errormsg={this.props.formState.elementerror.space}
          />
          <AdminSelect
            prefix="admin"
            name="course"
            label="Course"
            value={this.props.formState.course}
            list={this.props.courses}
            listitem="title"
            onChange={this.props.onChange}
            readonly={this.props.formState.readonly}
            haserror={this.props.formState.elementerror.course !== ""}
            errormsg={this.props.formState.elementerror.course}
          />
        </fieldset>
    );
  }
};
export default AdminTimeForm;