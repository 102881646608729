import React from 'react';
import './Admin.css'
import AdminBaseTable from './AdminBaseTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faFileCircleMinus, faCircleDot, faPen} from '@fortawesome/free-solid-svg-icons';
import {findObjectById, findIdInObject} from '../Helpers'

class AdminRegistrationTable extends AdminBaseTable
{

  isMarked = (editState) => {
    if(editState === 1)
    {
      return true;
    }
    return false;
  }

  hasCourse = (courses, id) => {
    if(findIdInObject(courses, "registration", id) !== undefined)
    {
      return true;
    }
    return false;
  }

  getCourseObject = (courses, registration) => {
    let object = findIdInObject(courses, "registration", registration.id)
    if(object !== undefined)
    {
      return object;
    }
    return {id: "", 
            title: registration.title, 
            person: registration.name,  
            lang: registration.lang, 
            level: registration.level, 
            tool: registration.tool, 
            participants: registration.name, 
            description: registration.description, 
            registration: registration.id};
  }

  getElement = () =>
  {
    return this.props.registrations;
  }

  compareFunction = (a, b) => 
  {
    const element = this.state.compareType;
    if(element === "name" || element === "email" || element === "title" || element === "tool")
    {
      if(this.state.isAscending)
        return b[element].localeCompare(a[element])
      else
        return a[element].localeCompare(b[element])
    }
    else if(element === "id" || element === "lang" || element === "level" || element === "participants" || element === "duration")
    {
      if(this.state.isAscending)
        return b[element] - a[element]
      else
        return a[element] - b[element]
    }
    return 0;
  }

  getHeader = () =>
  {
    return (
      <div className="admin-row">
        <div className="admin-column admin-column-id" onClick={() => this.setCompare("id")}>ID</div>
        <div className="admin-column admin-column-small" onClick={() => this.setCompare("name")}>Name</div>
        <div className="admin-column admin-column-small" onClick={() => this.setCompare("email")}>E-Mail</div>
        <div className="admin-column admin-column-small" onClick={() => this.setCompare("ticket")}>Ticket</div>
        <div className="admin-column admin-column-small" onClick={() => this.setCompare("title")}>Title</div>
        <div className="admin-column admin-column-small" onClick={() => this.setCompare("lang")}>Lang.</div>
        <div className="admin-column admin-column-small" onClick={() => this.setCompare("level")}>Level</div>
        <div className="admin-column admin-column-small" onClick={() => this.setCompare("tool")}>Tool</div>
        <div className="admin-column admin-column-small" onClick={() => this.setCompare("participants")}>Part.</div>
        <div className="admin-column admin-column-small" onClick={() => this.setCompare("duration")}>Duration</div>
        <div className="admin-column admin-column-options">Options</div>
      </div>
    );
  }

  getTableElements = () =>
  {
    return[
      this.getElement().map((registration, key) => {
      return (
        <div className={`admin-row ${key % 2 === 0 ?(registration.updating ?"admin-updating-even":"admin-row-even"):(registration.updating ?"admin-updating-odd":"admin-row-odd")}`} key={registration.id}>
          <div className="admin-column admin-column-id">{registration.id}</div>
          <div className="admin-column admin-column-small">{registration.name}</div>
          <div className="admin-column admin-column-small">{registration.email}</div>
          <div className="admin-column admin-column-small">{findObjectById(this.props.definitions.yesno, registration.ticket).name}</div>
          <div className="admin-column admin-column-small">{registration.title}</div>
          <div className="admin-column admin-column-small">{findObjectById(this.props.definitions.languages, registration.lang).name}</div>
          <div className="admin-column admin-column-small">{findObjectById(this.props.definitions.levels, registration.level).name}</div>
          <div className="admin-column admin-column-small">{registration.tool}</div>
          <div className="admin-column admin-column-small">{registration.participants}</div>
          <div className="admin-column admin-column-small">{registration.duration}</div>
          <div className="admin-column admin-column-options">
            <button 
                className={"admin-icon" + (this.isMarked(registration.editState)?" admin-icongreen":" admin-iconred")}
                onClick={() => this.props.markRegistration(key)}
            >
              <FontAwesomeIcon icon={ faPen }/>
            </button>  
            <button 
                className={"admin-icon" + (this.hasCourse(this.props.courses, registration.id)?" admin-icongreen":" admin-iconred")}
                onClick={() => this.props.gotoCourse(this.getCourseObject(this.props.courses, registration))}
            >
              <FontAwesomeIcon icon={ faCircleDot }/>
            </button>  
            <button
                className="admin-icon"
                onClick={() => this.props.updateRegistration(key)}
            >
              <FontAwesomeIcon icon={ faEdit }/>
            </button>
            <button 
                className="admin-icon"
                onClick={() => this.props.deleteRegistration(key)}
            >
              <FontAwesomeIcon icon={ faFileCircleMinus }/>
            </button>
          </div>
        </div>
        );
      })
    ]
  }
}
export default AdminRegistrationTable;