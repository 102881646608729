import React from 'react';
import './Admin.css'

class AdminCheckbox extends React.Component 
{
  render() 
  {
    return (
      <div className={this.props.prefix + "-field"}>
          <legend className={this.props.prefix + "-legend"}>{this.props.legend}</legend> 
          <input className={this.props.prefix + "-checkbox" + (this.props.haserror? " " + this.props.prefix + "-input-element-error": " " + this.props.prefix + "-input-element-noerror") + (this.props.readonly?" " + this.props.prefix + "-disabled":"")} type="checkbox" name={this.props.name} min={this.props.min} checked={this.props.value} onChange={this.props.onChange} readOnly={this.props.readonly} />
          <label className={this.props.prefix + "-label"}>{this.props.label}</label>
          <div className={this.props.prefix + "-input-error"}>{this.props.errormsg}</div>
      </div>
    );
  }
};

export default AdminCheckbox;