import React from 'react';
import './Admin.css'

class AdminSelect extends React.Component 
{
  getOptionList(list)
  {
    let elementtag = "name"
    if(this.props.listitem !== undefined)
    {
      elementtag = this.props.listitem;
    }
    if(list !== undefined)
    {
      return list.map((element) => {
        return (
          <option key={element.id} value={element.id}>{element[elementtag]}</option>
        );
      })
    }
  }
  
  render() 
  {
    return (
      <div className={this.props.prefix + "-field"}>
        <label className={this.props.prefix + "-label"}>{this.props.label}</label>
        <select className={this.props.prefix + "-selected" + (this.props.haserror? " " + this.props.prefix + "-input-element-error": " " + this.props.prefix + "-input-element-noerror") + (this.props.readonly?" " + this.props.prefix + "-disabled":"")} name={this.props.name} value={this.props.value} onChange={this.props.onChange} disabled={this.props.readonly}>   
          {this.getOptionList(this.props.list) }
        </select>
        <div className={this.props.prefix + "-input-error"}>{this.props.errormsg}</div>
      </div>
    );
  }
};

export default AdminSelect;