import React from 'react';
import './Board.css'
import TimeLine from './TimeLine'
import TimeLineHeader from './TimeLineHeader'

class TimeColumn extends React.Component 
{
  renderTimeLine(timesettings) 
  {
    console.log(timesettings)
    let content = [];
    for (let i = timesettings.start; i < timesettings.end; i++) 
    {
      const hourid = i>=24?i-24:i;
      content.push(<TimeLine key={i - timesettings.start} index={i - timesettings.start} value={hourid}/>);
    }
    return content;
  }

  render() 
  {
    return (
      <div ref={this.myInput} className="timecolumn" >
        <TimeLineHeader serverdata={this.props.serverdata} selectedDay={this.props.selectedDay}/>
        {this.renderTimeLine(this.props.timesettings)}
      </div>
    );
  }
}
export default TimeColumn;