import React from 'react';
import { StyleRoot } from 'radium';
import './Board.css'
import ArrowButton from './ArrowButton';
import SpaceColumn from './SpaceColumn';
import {headerHeight,hourHeightScreen,hourHeightPrint} from '../Helpers'

class SpaceTable extends React.Component 
{
  constructor(props)
  {
    super(props);
    this.ref = React.createRef();
    this.state = {      
      scrollSettings: {
        navBarTravelling: false,
        navBarTravelDirection: "",
        navBarTravelDistance: 250
      },
      spaceScrollLeft: 0
    };  
  }

  listenToScroll = () => {
    const scrollDemo = document.querySelector("#SpaceTable");
    this.setState({
      spaceScrollLeft: scrollDemo.scrollLeft,
    })
  } 

  handleAdvancerLeft()
  {
    this.ref.current.scrollLeft -= this.state.scrollSettings.navBarTravelDistance;
  }

  handleAdvancerRight(e)
  {
    this.ref.current.scrollLeft += this.state.scrollSettings.navBarTravelDistance;
  }

  renderNavigationButtons() 
  {
    let content = [];
    content.push(<ArrowButton key="left" className="advancer advancerleft" subClassName="advancericon" direction="left" onClickButton={() => this.handleAdvancerLeft()}/>);
    content.push(<ArrowButton key="right" className="advancer advancerright" subClassName="advancericon" direction="right" onClickButton={() => this.handleAdvancerRight()}/>);
    return content;
  }


  renderSpaceColumn(timesettings, serverdata, spaceScrollLeft) 
  {
    let content = [];
    if(serverdata !== undefined)
    {
      let definitionsData = serverdata.definitions;
      if(definitionsData !== undefined)
      {
        let spaceNames = definitionsData.spacenames;
        if(spaceNames !== undefined)
        {
          spaceNames.forEach(value =>
          {
            if(value.tag !== "firespace")
              {
              content.push(
                <SpaceColumn 
                  key={value.name} 
                  timesettings={timesettings} 
                  serverdata={serverdata} 
                  id={value.id} 
                  tag={value.tag} 
                  name={value.name} 
                  selectedDay={this.props.selectedDay} 
                  spaceScrollLeft={spaceScrollLeft}
                  onClickLine={(e, element) => this.props.onClickLine(e, element)}/>);
              }
          });
        }
      }
    }
    return content;
  }
  
  componentDidMount()
  {
    const scrollDemo = document.querySelector("#SpaceTable");
    scrollDemo.addEventListener('scroll', this.listenToScroll)
  }

  componentWillUnmount() {
    const scrollDemo = document.querySelector("#SpaceTable");
    scrollDemo.removeEventListener('scroll', this.listenToScroll)
  }

  render() 
  {
    let currentHeightScreen = headerHeight;
    let currentHeightPrint = headerHeight;
    if(this.props.timesettings !== undefined)
    {
      if(this.props.timesettings.start !== undefined && 
         this.props.timesettings.start !== Infinity && 
         this.props.timesettings.start !== -Infinity && 
         this.props.timesettings.end !== undefined && 
         this.props.timesettings.end !== Infinity && 
         this.props.timesettings.end !== -Infinity)
      {
        currentHeightScreen = (this.props.timesettings.end - this.props.timesettings.start) * hourHeightScreen + headerHeight + 32;
        currentHeightPrint = (this.props.timesettings.end - this.props.timesettings.start) * hourHeightPrint + headerHeight + 32;
      }
    }
    const stylenew = {
      '@media screen': {
        height: currentHeightScreen,
      },
      '@media print': {
        height: currentHeightPrint,
      },
    };
    return (
      <StyleRoot>
        <div id= "SpaceTable" className="spacetable" style={stylenew} ref={this.ref}>
          {this.renderNavigationButtons()}
          {this.renderSpaceColumn(this.props.timesettings, this.props.serverdata, this.state.spaceScrollLeft)}

        </div>
      </StyleRoot>
    );
  }
}
export default SpaceTable;