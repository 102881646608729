import React from 'react';
import './Admin.css'
import AdminBaseForm from './AdminBaseForm';
import AdminInput from './AdminInput';
import AdminSelect from './AdminSelect';
import AdminTextarea from './AdminTextarea';

class AdminCourseForm extends AdminBaseForm 
{
  getFieldset = () =>
  {
    return (
        <fieldset className="admin-fieldset">
          <AdminInput
            prefix="admin"
            name="title"
            label="Title"
            type="text"
            value={this.props.formState.title}
            onChange={this.props.onChange}
            readonly={this.props.formState.readonly}
            haserror={this.props.formState.elementerror.title !== ""}
            errormsg={this.props.formState.elementerror.title}
          />
          <AdminInput
            prefix="admin"
            name="person"
            label="Person"
            type="text"
            value={this.props.formState.person}
            onChange={this.props.onChange}
            readonly={this.props.formState.readonly}
            haserror={this.props.formState.elementerror.person !== ""}
            errormsg={this.props.formState.elementerror.person}
          />
          <AdminSelect
            prefix="admin"
            name="lang"
            label="Language"
            value={this.props.formState.lang}
            list={this.props.definitions.languages}
            onChange={this.props.onChange}
            readonly={this.props.formState.readonly}
            haserror={this.props.formState.elementerror.lang !== ""}
            errormsg={this.props.formState.elementerror.lang}
          />
          <AdminSelect
            prefix="admin"
            name="level"
            label="Level"
            value={this.props.formState.level}
            list={this.props.definitions.levels}
            onChange={this.props.onChange}
            readonly={this.props.formState.readonly}
            haserror={this.props.formState.elementerror.level !== ""}
            errormsg={this.props.formState.elementerror.level}
          />
          <AdminInput
            prefix="admin"
            name="tool"
            label="Tool"
            type="text"
            value={this.props.formState.tool}
            onChange={this.props.onChange}
            readonly={this.props.formState.readonly}
            haserror={this.props.formState.elementerror.tool !== ""}
            errormsg={this.props.formState.elementerror.tool}
          />
          <AdminInput
            prefix="admin"
            name="participants"
            label="Participants"
            type="text"
            value={this.props.formState.participants}
            onChange={this.props.onChange}
            readonly={this.props.formState.readonly}
            haserror={this.props.formState.elementerror.participants !== ""}
            errormsg={this.props.formState.elementerror.participants}
          />
          <AdminTextarea
            prefix="admin"
            name="description"
            label="Description"
            value={this.props.formState.description}
            onChange={this.props.onChange}
            readonly={this.props.formState.readonly}
            haserror={this.props.formState.elementerror.description !== ""}
            errormsg={this.props.formState.elementerror.description}
          />
        </fieldset>
    );
  }
};
export default AdminCourseForm;