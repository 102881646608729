import React from 'react';
import './Board.css'

class TimeLine extends React.Component 
{
  calculateGermanTimeformat(timeIn24h)
  {
    return Number(timeIn24h).toString() + ":00 Uhr";
  }

  calculateEnglishTimeformat(timeIn24h)
  {
    let timeIn12h = Number(timeIn24h)>12?Number(timeIn24h)-12:Number(timeIn24h);
    let timeFormat = Number(timeIn24h)>11?":00 pm":":00 am";
    return Number(timeIn12h).toString() + timeFormat;
  }

  render() 
  {
    let timestyle = "timeline texttime hourheigth "
    if(this.props.index % 2 === 1)
      timestyle += "timebackodd"
    else
      timestyle += "timebackeven"
    return (
      <div className={timestyle}>
        <div>{this.calculateGermanTimeformat(this.props.value)}</div>
        <div>{this.calculateEnglishTimeformat(this.props.value)}</div>
      </div>
    );
  }
}
export default TimeLine;