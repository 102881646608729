import React from 'react';
import './Workshop.css';


class WorkshopCommited extends React.Component 
{
  render() 
  {
    return (
      <div className="workshop-commitedtext">
        <div>Your workshop registration has been successfully submitted.</div>
        <div>Thank you for wanting to support the Phoenix Fire Convention with your workshop.</div>
        <div>We will contact you as soon as our workshop team has processed your registration.</div>
        <div>Please be patient, this may take a while</div>  
        <br />
        <a className="workshop-link" href="./registerworkshop">Go back to registration</a>
      </div> 
    )
  };
}
export default WorkshopCommited;