import React from 'react';
import './Admin.css'

class AdminTextarea extends React.Component 
{
  render() 
  {
    return (
      <div className={this.props.prefix + "-field"}>
          <label className={this.props.prefix + "-label"}>{this.props.label}</label>
          <div className={this.props.prefix + "-sublabel"}>{this.props.sublabel}</div>  
          <textarea className={this.props.prefix +  "-textarea" + (this.props.haserror? " " + this.props.prefix + "-input-element-error": " " + this.props.prefix + "-input-element-noerror") + (this.props.readonly?" " + this.props.prefix + "-disabled":"")} type="text" name={this.props.name} value={this.props.value} onChange={this.props.onChange} readOnly={this.props.readonly}/>
          <div className={this.props.prefix + "-input-error"}>{this.props.errormsg}</div>
      </div>
    );
  }
};

export default AdminTextarea;