export const headerHeight = 53;
export const headerHeightPrintOffset = -14;
export const hourHeightScreen = 150;
export const hourHeightPrint = 80;
export const hourHeightPrintOffset = 2;
export const spacelineColors = ["", "level_1", "level_2", "level_3", "level_4", "level_s"];
export function findObjectById (data, id)
{
  let returnItem = id.toString();
  if(data !== undefined)
  {
    let foundObject = data.find(obj => {
      return obj.id === id;
    });
    if(foundObject !== undefined)
    {
      returnItem =  foundObject;
    }
  }
  return returnItem;
}
export function findIdInObject (data, tag, id)
{
  let returnItem = undefined;
  if(data !== undefined)
  {
    let foundObject = data.find(obj => {
      return obj[tag] === id;
    });
    if(foundObject !== undefined)
    {
      returnItem = foundObject;
    }
  }
  return returnItem;
}

export function getStartTimeWithOffset(startHour)
{
  return Number(startHour) > 6?Number(startHour):Number(startHour)+24;
}
