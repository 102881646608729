import React from 'react';
import './Admin.css'
import AdminBaseTable from './AdminBaseTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faFileCircleMinus, faCircleLeft } from '@fortawesome/free-solid-svg-icons';
import {findObjectById} from '../Helpers'

class AdminTimesTable extends AdminBaseTable 
{
  getElement = () =>
  {
    return this.props.dates;
  }

  compareFunction = (a, b) => 
  {
    const element = this.state.compareType;
    if(element === "starttime" )
    {
      if(this.state.isAscending)
        return b[element].localeCompare(a[element])
      else
        return a[element].localeCompare(b[element])
    }
    else if(element === "id" || element === "duration" || element === "day" || element === "space" || element === "course")
    {
      if(this.state.isAscending)
        return b[element] - a[element]
      else
        return a[element] - b[element]
    }
    return 0;
  }

  getHeader = () =>
  {
    return (
      <div className="admin-row">
        <div className="admin-column admin-column-id" onClick={() => this.setCompare("id")}>ID</div>
        <div className="admin-column admin-column-extrasmall" onClick={() => this.setCompare("starttime")}>Starttime</div>
        <div className="admin-column admin-column-extrasmall" onClick={() => this.setCompare("duration")}>Duration</div>
        <div className="admin-column admin-column-medium" onClick={() => this.setCompare("day")}>Day</div>
        <div className="admin-column admin-column-medium" onClick={() => this.setCompare("space")}>Space</div>
        <div className="admin-column admin-column-extralarge" onClick={() => this.setCompare("course")}>Course</div>
        <div className="admin-column admin-column-options" >Options</div>
      </div>
    );
  }

  getTableElements = () =>
  {
    return[
      this.getElement().map((date, key) => {
        return (
          <div className={`admin-row ${key % 2 === 0 ?(date.updating ?"admin-updating-even":"admin-row-even"):(date.updating ?"admin-updating-odd":"admin-row-odd")}`} key={date.id}>
            <div className="admin-column admin-column-id">{date.id}</div>
            <div className="admin-column admin-column-extrasmall">{date.starttime.substring(0, date.starttime.length - 3)}</div>
            <div className="admin-column admin-column-extrasmall">{date.duration}</div>
            <div className="admin-column admin-column-medium">{findObjectById(this.props.definitions.days, date.day).name}</div>
            <div className="admin-column admin-column-medium">{findObjectById(this.props.definitions.spacenames, date.space).name}</div>
            <div className="admin-column admin-column-extralarge">{findObjectById(this.props.courses, date.course).title}</div>
            <div className="admin-column admin-column-options">
              <button 
                 className={"admin-icon"}
                 onClick={() => this.props.gotoCourse(findObjectById(this.props.courses, date.course))}
              >
                <FontAwesomeIcon icon={ faCircleLeft }/>
              </button>  
              <button
                  className="admin-icon"
                  onClick={() => this.props.updateDate(key)}
              >
                <FontAwesomeIcon icon={ faEdit }/>
              </button>
              <button 
                 className="admin-icon"
                 onClick={() => this.props.deleteDate(key)}
              >
                <FontAwesomeIcon icon={ faFileCircleMinus }/>
              </button>
            </div>
          </div>
        );
      })
    ]
  }
}
export default AdminTimesTable;