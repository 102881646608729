import React from 'react';
import './Admin.css'

class Login extends React.Component {
    
  render() 
  {
    return (
        <form className="admin-form" onSubmit={this.props.onSubmit}>
            <fieldset className="login-fieldset">
                <label className="login-label">Password:</label>
                <input className="login-input" type="password" value={this.props.value} onChange={this.props.onChange}/>
            </fieldset>
            <button className="login-button" type="submit">Login</button>
        </form>
    );
  }
}
export default Login;