import React from 'react';
import './Board.css'
import {spacelineColors} from '../Helpers'

class SpaceLineBig extends React.Component 
{
  render() 
  {
    let participantsstring = "participants: "
    if(this.props.element.item.participants === 0)
    {
      participantsstring = participantsstring + "-";
    }
    else
    {
      participantsstring = participantsstring + this.props.element.item.participants;
    }
    return (
      <div className={"spacelinebig " + spacelineColors[this.props.element.item.levelindex]} style={{visibility: this.props.element.active?"visible":"hidden" }}>
        <div className="textclose" onClick={(e) => this.props.onClickClose(e)}>&times;</div>
        <div className="textbig textwrap textspacebig">{this.props.element.item.title}</div>
        <div className="textsmallbold textleft">{this.props.element.item.person}</div>
        <div className="textsmallbold textright">{this.props.element.item.duration + "min."}</div>
        <br/>
        <div className="textsmallbold textleft">{this.props.element.item.level}</div>
        <div className="textsmallbold textright">{this.props.element.item.lang}</div>
        <br/>
        <div className="textsmallbold textleft">{participantsstring}</div>
        <div className="textsmallbold textright">{this.props.element.item.tool}</div>
        <br className="textspace"/>
        <div className="textsmallnormal textleft textwrap">{this.props.element.item.description}</div>

      </div>
    );
  }
}
export default SpaceLineBig;