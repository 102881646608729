import React from 'react';
import './Admin.css'

class AdminBaseForm extends React.Component 
{
  getFieldset()
  {
    throw new Error("Abstract Method getFieldset() has no implementation");
  }

  render() 
  { 
    return (
      <form className="admin-form" onSubmit={this.props.onSubmit}>
        {this.getFieldset()}
        <button className="admin-button" type="submit">{this.props.formState.mode}</button>
        <button className="admin-button" type="button" style={{visibility: this.props.formState.showCancel?"visible":"hidden" }} onClick={() => this.props.onCancel()}>{"cancel"}</button>
      </form>
    );
  }
};
export default AdminBaseForm;