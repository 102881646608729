import React from 'react';
import './Workshop.css';
import WorkshopTitle from './WorkshopTitle';
import WorkshopForm from './WorkshopForm';
import WorkshopCommited from './WorkshopCommited';
import {findObjectById} from '../Helpers'


class WorkshopRegister extends React.Component {
  elementerrorRegistration = {
    name: "",
    email: "",
    emailconfirm: "",
    personinfo: "",
    ticket: "",
    title: "",
    lang: "",
    level: "",
    tool: "",
    participants: "",
    description: "",
    knowledge: "",
    duration: "",
    timeinfo: "",
    extinfo: "",
    check: "",
  }
    
  formStateRegistration = {
    id: '',
    name: "",
    email: "",
    emailconfirm: "",
    personinfo: "",
    ticket: 0,
    title: "",
    lang: 1,
    langtext: "",
    level: 1,
    leveltext: "",
    tool: "",
    participants: 0,
    description: "",
    knowledge: "",
    duration: 45,
    timeinfo: "",
    extinfo: "",
    check: false,
    mode: "submit",
    showCancel: false,
    updating: false,
    readonly: false,
    elementerror: this.elementerrorRegistration
  }
  
  state = {
    showCommitPage: false,
    formStateRegistration: this.formStateRegistration
  }; 

  resetFormState = () => {
    this.setState({
      formStateRegistration: this.formStateRegistration
    });
  };

  checkForm = () => {
    this.resetCheck();
    let elementerror = {  name: "",
                          email: "",
                          emailconfirm: "",
                          personinfo: "",
                          ticket: "", 
                          title: "",
                          lang: "",
                          level: "",
                          tool: "",
                          participants: "",
                          description: "",
                          knowledge: "",
                          duration: "",
                          timeinfo: "",
                          extinfo: "",
                          check: "",
                        };


    let notEmpty = this.checkFormNotEmpty(elementerror);
    let emailIsIdentical = this.checkEmailIdentical(elementerror);
    let greaterZero = this.checkValueGreaterZero(elementerror);
    let checkPoliciy = this.checkPoliciy(elementerror);
    this.setErrorState(elementerror);
    return notEmpty && emailIsIdentical && greaterZero && checkPoliciy;
  }

  resetCheck = () => {
   
    this.setState({
      formStateRegistration: { 
        ...this.state.formStateRegistration,
        elementerror: this.elementerrorRegistration,
      }
    })

  };

  checkFormNotEmpty = (elementerror) => {
    let status = true;
    let emptyerror = "This field is required."

    if(this.state.formStateRegistration.name === "" || 
      this.state.formStateRegistration.email === "" || 
      this.state.formStateRegistration.emailconfirm === "" || 
      this.state.formStateRegistration.title === "" || 
      this.state.formStateRegistration.lang === "" || 
      this.state.formStateRegistration.level === "" || 
      this.state.formStateRegistration.participants === "" || 
      this.state.formStateRegistration.description === "" || 
      this.state.formStateRegistration.knowledge === "" || 
      this.state.formStateRegistration.duration === "" || 
      this.state.formStateRegistration.timeinfo === "" || 
      this.state.formStateRegistration.extinfo === "")
    {
      elementerror.name = this.state.formStateRegistration.name === ""?emptyerror:"";
      elementerror.email = this.state.formStateRegistration.email === ""?emptyerror:"";
      elementerror.emailconfirm = this.state.formStateRegistration.emailconfirm === ""?emptyerror:"";
      elementerror.title = this.state.formStateRegistration.title === ""?emptyerror:"";
      elementerror.lang = this.state.formStateRegistration.lang === ""?emptyerror:"";
      elementerror.level = this.state.formStateRegistration.level === ""?emptyerror:"";
      elementerror.participants = this.state.formStateRegistration.participants === ""?emptyerror:"";
      elementerror.description = this.state.formStateRegistration.description === ""?emptyerror:"";
      elementerror.knowledge = this.state.formStateRegistration.knowledge === ""?emptyerror:"";
      elementerror.duration = this.state.formStateRegistration.duration === ""?emptyerror:"";
      elementerror.timeinfo = this.state.formStateRegistration.timeinfo === ""?emptyerror:"";
      elementerror.extinfo = this.state.formStateRegistration.extinfo === ""?emptyerror:"";
      status = false;
    }
    return status;
  };

  checkEmailIdentical = (elementerror) => {
    let status = true;
    let emailerror = "The email adresses must be identical."

    if(this.state.formStateRegistration.email !== this.state.formStateRegistration.emailconfirm)
    {
      elementerror.email = emailerror;
      elementerror.emailconfirm = emailerror;
      status = false;
    }
    return status;
  };

  checkValueGreaterZero = (elementerror) => {
    let status = true;
    let zeroerror = "This value must be greater zero."

    if(Number(this.state.formStateRegistration.duration) === 0)
    {
      elementerror.duration = zeroerror;
      status = false;
    }
    return status;
  };

  checkPoliciy = (elementerror) => {
    let status = true;
    let checkerror = "The privacy policy must be accepted."

    if(this.state.formStateRegistration.check === false)
    {
      elementerror.check = checkerror;
      status = false;
    }
    return status;
  };

  setErrorState = (elementerror) => {

    this.setState({
      formStateRegistration: { 
        ...this.state.formStateRegistration,
        elementerror: elementerror,
      },
    })

  }

  onChange = event => {
    let result = event.target.value;
    if( event.target.name === "duration" || event.target.name === "participants")
    {
      result = event.target.value.replace(/\D/g, '');
      if( result !== "" )
      {
        result = Number(result);
      }
    }
    else if(event.target.name === "check")
    {
      result = !this.state.formStateRegistration.check;
    }

    this.setState({
        formStateRegistration: {
            ...this.state.formStateRegistration,
            [event.target.name]: result,
        }
    });
  };
  
  setListElements = (registration) => {
    registration.langtext = findObjectById(this.props.definitions.languages, Number(registration.lang)).fullname;
    registration.leveltext = findObjectById(this.props.definitions.levels, Number(registration.level)).name;
  };


  onSubmit = event => {
    const {formStateRegistration} = this.state;
    event.preventDefault();
  
    if(this.checkForm())
    {
      this.setListElements(formStateRegistration);
      this.props.submitRegistration(formStateRegistration);
      this.resetFormState();
      this.setState({
          showCommitPage : true,
      });
    }
  };

  render() 
  {
    return (
      <div>
        <WorkshopTitle />
        {this.state.showCommitPage?
          <WorkshopCommited /> :
          <WorkshopForm 
            definitions={this.props.definitions}
            formState={this.state.formStateRegistration}
            onChange={this.onChange}
            onSubmit={this.onSubmit}
          />
        }
      </div>
    );
  }
}
export default WorkshopRegister;