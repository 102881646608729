import React from 'react';
import './Board.css'
import ArrowSvg from './ArrowSvg'

class ArrowButton extends React.Component 
{
  render() 
  {
    return (
      <button
        className={this.props.className}
        style={this.props.style}
        type="button"
        onClick={(e) => this.props.onClickButton()}
      >
        <ArrowSvg className={this.props.subClassName} direction={this.props.direction}/>
      </button>
    );
  }
}
export default ArrowButton;