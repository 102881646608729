import React from 'react';
import './Admin.css'
import AdminBaseForm from './AdminBaseForm';
import AdminInput from './AdminInput';
import AdminSelect from './AdminSelect';
import AdminTextarea from './AdminTextarea';

class AdminRegistrationForm extends AdminBaseForm
{
  getFieldset = () =>
  {
    return (
      <fieldset className="admin-fieldset">
          <AdminInput
            prefix="admin"
            name="name"
            label="Name"
            type="text"
            value={this.props.formState.name}
            onChange={this.props.onChange}
            readonly={this.props.formState.readonly}
            haserror={this.props.formState.elementerror.name !== ""}
            errormsg={this.props.formState.elementerror.name}
          />
          <AdminInput
            prefix="admin"
            name="email"
            label="E-mail"
            type="text"
            value={this.props.formState.email}
            onChange={this.props.onChange}
            readonly={this.props.formState.readonly}
            haserror={this.props.formState.elementerror.email !== ""}
            errormsg={this.props.formState.elementerror.email}
          />
          <AdminTextarea
            prefix="admin"
            name="personinfo"
            label="Person info"
            value={this.props.formState.personinfo}
            onChange={this.props.onChange}
            readonly={this.props.formState.readonly}
            haserror={this.props.formState.elementerror.personinfo !== ""}
            errormsg={this.props.formState.elementerror.personinfo}
          />
          <AdminSelect
            prefix="admin"
            name="ticket"
            label="Ticket"
            value={this.props.formState.ticket}
            list={this.props.definitions.yesno}
            onChange={this.props.onChange}
            readonly={this.props.formState.readonly}
            haserror={this.props.formState.elementerror.ticket !== ""}
            errormsg={this.props.formState.elementerror.ticket}
          />
          <AdminInput
            prefix="admin"
            name="title"
            label="Course title"
            type="text"
            value={this.props.formState.title}
            onChange={this.props.onChange}
            readonly={this.props.formState.readonly}
            haserror={this.props.formState.elementerror.title !== ""}
            errormsg={this.props.formState.elementerror.title}
          />
          <AdminSelect
            prefix="admin"
            name="lang"
            label="Language"
            value={this.props.formState.lang}
            list={this.props.definitions.languages}
            onChange={this.props.onChange}
            readonly={this.props.formState.readonly}
            haserror={this.props.formState.elementerror.lang !== ""}
            errormsg={this.props.formState.elementerror.lang}
          />
          <AdminSelect
            prefix="admin"
            name="level"
            label="Level"
            value={this.props.formState.level}
            list={this.props.definitions.levels}
            onChange={this.props.onChange}
            readonly={this.props.formState.readonly}
            haserror={this.props.formState.elementerror.level !== ""}
            errormsg={this.props.formState.elementerror.level}
          />
          <AdminInput
            prefix="admin"
            name="tool"
            label="Tool"
            type="text"
            value={this.props.formState.tool}
            onChange={this.props.onChange}
            readonly={this.props.formState.readonly}
            haserror={this.props.formState.elementerror.tool !== ""}
            errormsg={this.props.formState.elementerror.tool}
          />
          <AdminInput
            prefix="admin"
            name="participants"
            label="Participants"
            type="text"
            value={this.props.formState.participants}
            onChange={this.props.onChange}
            readonly={this.props.formState.readonly}
            haserror={this.props.formState.elementerror.participants !== ""}
            errormsg={this.props.formState.elementerror.participants}
          />
          <AdminTextarea
            prefix="admin"
            name="description"
            label="Description"
            value={this.props.formState.description}
            onChange={this.props.onChange}
            readonly={this.props.formState.readonly}
            haserror={this.props.formState.elementerror.description !== ""}
            errormsg={this.props.formState.elementerror.description}
          />
          <AdminTextarea
            prefix="admin"
            name="knowledge"
            label="Knowledge"
            value={this.props.formState.knowledge}
            onChange={this.props.onChange}
            readonly={this.props.formState.readonly}
            haserror={this.props.formState.elementerror.knowledge !== ""}
            errormsg={this.props.formState.elementerror.knowledge}
          />
          <AdminInput
            prefix="admin"
            name="duration"
            label="Duration"
            type="text"
            value={this.props.formState.duration}
            onChange={this.props.onChange}
            readonly={this.props.formState.readonly}
            haserror={this.props.formState.elementerror.duration !== ""}
            errormsg={this.props.formState.elementerror.duration}
          />
          <AdminTextarea
            prefix="admin"
            name="timeinfo"
            label="Time info"
            value={this.props.formState.timeinfo}
            onChange={this.props.onChange}
            readonly={this.props.formState.readonly}
            haserror={this.props.formState.elementerror.timeinfo !== ""}
            errormsg={this.props.formState.elementerror.timeinfo}
          />
          <AdminTextarea
            prefix="admin"
            name="extinfo"
            label="Additional info"
            value={this.props.formState.extinfo}
            onChange={this.props.onChange}
            readonly={this.props.formState.readonly}
            haserror={this.props.formState.elementerror.extinfo !== ""}
            errormsg={this.props.formState.elementerror.extinfo}
          />
        </fieldset>
    );
  }
};
export default AdminRegistrationForm;