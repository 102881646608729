import React from 'react';
import { StyleRoot } from 'radium';
import './Board.css'
import {spacelineColors} from '../Helpers'

class SpaceLine extends React.Component 
{
  render() 
  {
    const stylenew = {
      // Adding media query..
      '@media screen':{
        height: this.props.height_s + "px",
        top: this.props.pos_s + "px",
      },
      '@media print':{
        height: this.props.height_p,
        top: this.props.pos_p + "px",
      },
    };
    let participantsstring = "participants: "
    if(this.props.lineElement.participants === 0)
    {
      participantsstring = participantsstring + "-";
    }
    else
    {
      participantsstring = participantsstring + this.props.lineElement.participants;
    }
    return (
      <StyleRoot>
        <div className={"spaceline " + spacelineColors[this.props.lineElement.levelindex]} style={stylenew} onClick={(e) => this.props.onClickLine(e, this.props.lineElement)} >
          <div className="textmedium textwrap textspace">{this.props.lineElement.title}</div>
          <div className="textsmallbold textleft">{this.props.lineElement.person}</div>
          <div className="textsmallbold textright">{this.props.lineElement.duration + "min."}</div>
          <br/>
          <div className="textsmallbold textleft">{this.props.lineElement.level}</div>
          <div className="textsmallbold textright">{this.props.lineElement.lang}</div>
          <br/>
          <div className="textsmallbold textleft">{participantsstring}</div>
          <div className="textsmallbold textright">{this.props.lineElement.tool}</div>
          <br className="textspace spacelinevisibility"/>
          <div className="textsmallnormal textleft textwrap spacelinevisibility">{this.props.lineElement.description}</div>

        </div>
      </StyleRoot>
    );
  }
}
export default SpaceLine;